import React from "react";
import clsx from "clsx";
import { Link, Outlet } from "react-router-dom";
import { useAuthentication } from "../providers/AuthProvider";
import {
  Alert,
  AlertColor,
  AppBar,
  CssBaseline,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Toolbar,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import AssessmentIcon from "@mui/icons-material/Assessment";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ViewListIcon from "@mui/icons-material/ViewList";
import { usePopup } from "../providers/PopupProvider";

interface NavigationItem {
  icon: React.ComponentType;
  text: string;
  path?: string;
  onClick?(): void;
}

const useNavigationItems = (logout: () => void): NavigationItem[] => [
  {
    icon: AssessmentIcon,
    text: "Metrics",
    path: "/app/metrics",
  },
  {
    icon: ViewListIcon,
    text: "Campaigns",
    path: "/app/campaigns",
  },
  {
    icon: ExitToAppIcon,
    text: "Logout",
    onClick: logout,
  },
];

const DRAWER_WIDTH = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: DRAWER_WIDTH,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7),
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(7),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  snackbar: {
    marginTop: theme.spacing(7),
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Layout: React.FC = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { popup, closePopup } = usePopup();
  const { authenticated, logout } = useAuthentication();
  const navigationItems = useNavigationItems(logout);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {authenticated ? (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
              size="large">
              <MenuIcon />
            </IconButton>
          ) : undefined}
        </Toolbar>
      </AppBar>
      {authenticated ? (
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose} size="large">
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            {navigationItems.map((item) => {
              return item.path ? (
                <ListItem
                  button
                  key={item.text}
                  component={Link}
                  to={item.path}
                >
                  <ListItemIcon>{React.createElement(item.icon)}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              ) : (
                <ListItem button key={item.text} onClick={item.onClick}>
                  <ListItemIcon>{React.createElement(item.icon)}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItem>
              );
            })}
          </List>
        </Drawer>
      ) : undefined}
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {!!popup ? (
          <Snackbar
            className={classes.snackbar}
            open={!!popup}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            autoHideDuration={6000}
            onClose={closePopup}
          >
            <Alert onClose={closePopup} severity={popup.severity as AlertColor}>
              {popup.message}
            </Alert>
          </Snackbar>
        ) : undefined}
        <Outlet />
      </main>
    </div>
  );
};

export default Layout;
