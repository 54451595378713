import { API } from "./common";
import queryString from "querystring";

export interface Campaign {
  id: string;
  image_url: string;
  icon_url: string;
  click_url: string;
  a_domain: string;
  bids: number;
  impressions: number;
  clicks: number;
  enabled: boolean;
  first_seen: number;
  last_seen: number;
  device_os: {
    String: string,
    Valid: boolean
  };
  cpc: boolean;
  dimension: string;
}

interface ListRequest {
  deviceOS: string;
  start: string;
  end: string;
  page: number;
  size: number;
  sort: string[];
  cpc: string;
}

interface ListResponse {
  campaigns: Campaign[];
  total_elements: number;
  total_pages: number;
}

const list = async (payload: ListRequest): Promise<ListResponse> => {
  const params = queryString.stringify({
    device_os: payload.deviceOS,
    cpc: payload.cpc,
    start: payload.start,
    end: payload.end,
    page: payload.page,
    size: payload.size,
    sort: payload.sort,
  });

  const { status, data } = await API.get<ListResponse>(
    `/v1/campaigns?${params}`
  );
  if (status !== 200) throw Error(`status code: ${status}`);
  return data;
};

const updateStatus = async (payload: Campaign): Promise<null> => {
  const { status } = await API.put("/v1/campaigns", payload);
  if (status !== 200) throw Error(`status code: ${status}`);
  return null;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  list,
  updateStatus,
};
