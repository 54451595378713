import { API } from "./common";
import queryString from "querystring";

interface Metric {
  date: string;
  domain: string;
  dimension: string;
  requests: number;
  responses: number;
  win_notices: number;
  average_win_rate: number;
  impressions: number;
  average_cpm_rate: number;
  clicks: number;
  average_ctr: number;
  revenue: number;
  average_bid_cpm: number;
}

export interface Summary {
  total_bid_requests: number;
  valid_requests: number;
  total_impressions: number;
  average_win_rate: number;
  total_revenue: number;
  average_bid_cpm: number;
  updated_at: string;
}

interface SummaryRequest {
  start: string;
  end: string;
}

interface ListRequest {
  start: string;
  end: string;
  page: number;
  size: number;
  sort: string[];
}

interface ListResponse {
  metrics: Metric[];
  total_elements: number;
  total_pages: number;
}

interface ExportAsCSVRequest {
  start: string;
  end: string;
  sort: string[];
}

interface ExportAsCSVResponse {
  blob: Blob;
  filename: string;
}

const summary = async (payload: SummaryRequest): Promise<Summary> => {
  const params = queryString.stringify({
    start: payload.start,
    end: payload.end,
  });

  const { status, data } = await API.get<Summary>(
    `/v1/metrics/summary?${params}`
  );
  if (status !== 200) throw Error(`status code: ${status}`);
  return data;
};

const list = async (payload: ListRequest): Promise<ListResponse> => {
  const params = queryString.stringify({
    start: payload.start,
    end: payload.end,
    page: payload.page,
    size: payload.size,
    sort: payload.sort,
  });

  const { status, data } = await API.get<ListResponse>(`/v1/metrics?${params}`);
  if (status !== 200) throw Error(`status code: ${status}`);
  return data;
};

const exportAsCSV = async (
  payload: ExportAsCSVRequest
): Promise<ExportAsCSVResponse> => {
  const params = queryString.stringify({
    start: payload.start,
    end: payload.end,
    sort: payload.sort,
  });

  const { status, data, headers } = await API.get<Blob>(
    `/v1/metrics/export?${params}`,
    {
      responseType: "blob",
    }
  );

  if (status !== 200) throw Error(`status code: ${status}`);

  const disposition = headers["content-disposition"] as string;

  return {
    blob: data,
    filename: disposition.substring(disposition.lastIndexOf("=") + 1),
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  summary,
  list,
  exportAsCSV,
};
