import React from 'react';
import { TableCell } from '@mui/material';

interface KTableCellProps {
  overrides?: any;
  child: string | number | JSX.Element | JSX.Element[];
  show?: boolean;
}

const KTableCell: React.FC<KTableCellProps> = ({ overrides, child, show = true }) => {
  return show ? <TableCell {...overrides}>{child}</TableCell> : null;
};

export default KTableCell;
