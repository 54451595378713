import React from 'react';
import DataGrid, { HeaderSortingProps } from '../components/DataGrid';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  SortDirection,
  Select,
  Tab,
  Tabs,
  TableRow,
  Tooltip,
  styled,
} from '@mui/material';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import SettingsIcon from '@mui/icons-material/Settings';
import FilterListIcon from '@mui/icons-material/FilterList';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import CampaignService, { Campaign } from '../services/CampaignService';
import { usePopup } from '../providers/PopupProvider';
import { useAuthentication } from '../providers/AuthProvider';
import './Campaigns.css';
import ErrorHandler from '../services/ErrorHandler';
import CustomSelect, { HeaderCellProps } from "../components/CustomSelect";
import KTableCell from '../components/KTableCell';

const cells = [
  {
    id: 'last_seen',
    label: 'Last Seen',
    numeric: false,
    sortable: true,
    selected: true,
  },
  {
    id: 'id',
    label: 'ID',
    numeric: false,
    sortable: false,
    selected: true,
  },
  {
    id: 'image',
    label: 'Image',
    numeric: false,
    sortable: false,
    selected: true,
  },
  {
    id: 'dimension',
    label: 'Dimension',
    numeric: false,
    sortable: false,
    selected: true,
  },
  {
    id: 'enabled',
    label: 'Enabled',
    numeric: false,
    sortable: true,
    selected: true,
  },
  {
    id: 'image_url',
    label: 'Image URL',
    numeric: false,
    sortable: false,
    selected: true,
  },
  {
    id: 'a_domain',
    label: 'Adomain',
    numeric: false,
    sortable: false,
    selected: true,
  },
  {
    id: 'device_os',
    label: 'Device',
    numeric: false,
    sortable: true,
    selected: true,
  },
  {
    id: 'cpc',
    label: 'CPC',
    numeric: false,
    sortable: true,
    selected: true,
  },
  {
    id: 'bids',
    label: 'Bids',
    numeric: true,
    sortable: true,
    selected: true,
  },
  {
    id: 'impressions',
    label: 'Impressions',
    numeric: true,
    sortable: true,
    selected: true,
  },
  {
    id: 'clicks',
    label: 'Clicks',
    numeric: true,
    sortable: true,
    selected: true,
  },
];

const StyledTab = styled(Tab)(() => ({
  backgroundColor: '#1976d2',
  color: '#ffffff',
  borderRadius: '10px 10px 0 0',
  borderBottom: '5px solid #ffffff',
  '&.Mui-selected': {
    border: 0,
    color: '#ffffff',
  },
}));

const Campaigns: React.FC = () => {
  const [deviceOS, setDeviceOS] = React.useState<string>('undefined');
  const [cpc, setCPC] = React.useState('all');
  const [page, setPage] = React.useState(1);
  const [size, setSize] = React.useState(10);
  const [totalElements, setTotalElements] = React.useState(0);

  const [startDate, setStartDate] = React.useState<moment.Moment | null>(
    moment().subtract(7, 'day')
  );

  const [endDate, setEndDate] = React.useState<moment.Moment | null>(moment());
  const [focus, setFocus] = React.useState<FocusedInputShape | null>(null);
  const [
    settingsAnchor,
    setSettingsAnchor,
  ] = React.useState<null | HTMLElement>(null);
  const [headersStatus, setHeadersStatus] = React.useState<HeaderCellProps[]>(
    cells
  );

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setDeviceOS(newValue);
  };
  const handleSettingsOpen = (event: React.MouseEvent<HTMLElement>): void => {
    setSettingsAnchor(settingsAnchor ? null : event.currentTarget);
  };

  const handleDataSelection = (updatedHeaders: HeaderCellProps[]): void => {
    setRows(renderRows(campaigns, updatedHeaders));
    setHeadersStatus(updatedHeaders);
  };

  const handleFilterByCPC = (event: any) => {
    const value = event.target.value;
    setCPC(value);
  }

  const filters = [
    <Box
      display='flex'
      flexDirection='row'
      justifyContent='space-between'
      px={2}
      pt={2}
      key='date-range-picker'
    >
      <Box>
        <Tooltip title='Filter Data'>
          <IconButton size="large">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
        <DateRangePicker
          hideKeyboardShortcutsPanel
          startDate={startDate}
          startDateId='start-date'
          endDate={endDate}
          endDateId='end-date'
          onDatesChange={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          focusedInput={focus}
          onFocusChange={(focus) => setFocus(focus)}
          isOutsideRange={(date) => date.isAfter(moment())}
          minimumNights={0}
        />
        <FormControl style={{ minWidth: 80, paddingLeft: 12 }}>
          <InputLabel style={{ paddingLeft: 12 }} >CPC</InputLabel>
          <Select
            value={cpc}
            label='CPC'
            onChange={handleFilterByCPC}
          >
            <MenuItem value='all'>All</MenuItem>
            <MenuItem value='true'>True</MenuItem>
            <MenuItem value='false'>False</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        <Tooltip title='Edit Headers'>
          <IconButton onClick={handleSettingsOpen} size="large">
            <SettingsIcon />
          </IconButton>
        </Tooltip>
        <CustomSelect
          cells={headersStatus}
          open={Boolean(settingsAnchor)}
          anchorEl={settingsAnchor}
          onDataChange={handleDataSelection}
        />
      </Box>
    </Box>,
  ];

  const [sorting, setSorting] = React.useState<HeaderSortingProps>({
    fields: {
      bids: false as SortDirection,
      impressions: false as SortDirection,
      clicks: false as SortDirection,
      enabled: false as SortDirection,
      last_seen: 'desc' as SortDirection,
      device_os: false as SortDirection,
      cpc: false as SortDirection,
    },
    order: ['last_seen'],
  });

  const handleSortingChange = (key: string) => {
    const order = [...sorting.order];
    const index = order.indexOf(key);

    switch (sorting.fields[key]) {
      case false:
        if (index === -1) order.push(key);
        setSorting({ fields: { ...sorting.fields, [key]: 'desc' }, order });
        break;
      case 'desc':
        if (index === -1) order.push(key);
        setSorting({ fields: { ...sorting.fields, [key]: 'asc' }, order });
        break;
      default:
        if (index > -1) order.splice(index, 1);
        setSorting({ fields: { ...sorting.fields, [key]: false }, order });
        break;
    }
  };

  const header = {
    cells: headersStatus.filter((header) => header.selected),
    sorting,
    onSortingChange: handleSortingChange,
  };

  const [rows, setRows] = React.useState<JSX.Element[]>([]);
  const [campaigns, setCampaigns] = React.useState<Campaign[]>([]);

  const { showPopup } = usePopup();

  const { logout } = useAuthentication();

  const handleCampaignStatusChange = (i: number, campaigns: Campaign[]) => {
    const updated = {
      ...campaigns[i],
      enabled: !campaigns[i].enabled,
    };
    CampaignService.updateStatus(updated)
      .then(() => {
        campaigns.splice(i, 1, updated);
        setRows(renderRows(campaigns, headersStatus));
      })
      .catch((e: any) => ErrorHandler.checkAPICallError(e, showPopup, logout));
  };

  const overrides = {
    align: 'right',
  };

  const renderTag = (response: string):JSX.Element[] => {

    return response.split("\n").map((text) => <p>{text}</p>)
    
  }

  const renderRows = (campaigns: Campaign[], headers: HeaderCellProps[]) =>
    campaigns.map<JSX.Element>((campaign, i) => (
      <TableRow key={`data-grid-row-${i}`}>
        <KTableCell
          child={moment.unix(campaign.last_seen).format('YYYY-MM-DD')}
          show={headers[0].selected}
        />
        <KTableCell
          child={campaign.id.substring(0, 20)}
          show={headers[1].selected}
        />
        <KTableCell
          child={
            campaign.image_url ? (
              <img src={campaign.image_url} height='100' width='auto' alt='' />
            ) : (
              '-'
            )
          }
          show={headers[2].selected}
        />
        <KTableCell child={renderTag(campaign.dimension)} show={headers[3].selected} />
        <KTableCell
          child={
            <Checkbox
              color='primary'
              checked={campaign.enabled}
              onClick={() => handleCampaignStatusChange(i, campaigns)}
            />
          }
          show={headers[4].selected}
        />
        <KTableCell child={campaign.image_url} show={headers[5].selected} />
        <KTableCell child={campaign.a_domain} show={headers[6].selected} />
        <KTableCell
          child={
            campaign.device_os.String === '' ? 'N/A' : campaign.device_os.String
          }
          show={headers[7].selected}
        />
        <KTableCell
          child={campaign.cpc ? 'True' : 'False'}
          show={headers[8].selected}
        />
        <KTableCell
          overrides={overrides}
          child={campaign.bids.toString(10)}
          show={headers[9].selected}
        />

        <KTableCell
          overrides={overrides}
          child={campaign.impressions.toString(10)}
          show={headers[10].selected}
        />
        <KTableCell
          overrides={overrides}
          child={campaign.clicks.toString(10)}
          show={headers[11].selected}
        />
      </TableRow>
    ));

  const reloadCampaigns = React.useCallback(async () => {
    if (!startDate || !endDate) {
      return;
    }

    try {
      const sort = sorting.order
        .map((key) => ({ key, direction: sorting.fields[key] }))
        .filter(({ direction }) => direction)
        .map(({ key, direction }) => `${key},${direction}`);

      const { campaigns, total_elements } = await CampaignService.list({
        deviceOS,
        cpc,
        start: startDate.format('YYYY-MM-DD'),
        end: endDate.format('YYYY-MM-DD'),
        page,
        size,
        sort,
      });

      setRows(renderRows(campaigns, headersStatus));
      setTotalElements(total_elements);
      setCampaigns(campaigns);
    } catch (e: any) {
      ErrorHandler.checkAPICallError(e, showPopup, logout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    size,
    startDate,
    endDate,
    sorting,
    deviceOS,
    cpc,
    // renderRows,
    // setRows,
    // setTotalElements,
    showPopup,
    logout,
  ]);

  const handlePageClick = (event: any) => {
    const clickedType = event.target.type;
    if (settingsAnchor !== null && clickedType !== 'checkbox') {
      setSettingsAnchor(null);
    }
  };

  React.useEffect(() => {
    reloadCampaigns();
  }, [deviceOS, cpc, page, size, startDate, endDate, sorting, reloadCampaigns]);

  return (
    <Box onClick={handlePageClick}>
      <Tabs
        value={deviceOS}
        onChange={handleTabChange}
        TabIndicatorProps={{ style: { display: 'none' } }}
      >
        <StyledTab label='All' value='undefined' />
        <StyledTab label='KaiOS' value='KaiOS' />
        <StyledTab label='Non-KaiOS' value='Non-KaiOS' />
      </Tabs>
      <DataGrid
        filters={filters}
        header={header}
        rowHeight={100}
        rows={rows}
        page={page}
        size={size}
        totalElements={totalElements}
        onPageChange={setPage}
        onSizeChange={setSize}
      />
    </Box>
  );
};

export default Campaigns;
